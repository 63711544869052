<template>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <qr-code-scanner
                        @scanned="codeScanned"
                >
                </qr-code-scanner>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group mb-3">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fa fa-search"></i></span>
                        </div>
                        <input type="text" class="form-control" placeholder="Enter device label ID or IMEI" v-on:keyup.enter="findDevice()" v-model="searchText">
                        <span class="input-clear" v-if="searchText" @click="searchText = ''"><i class="fas fa-times-circle mb-0"></i></span>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-2 mt-3 mt-md-0 pl-md-0">
                <button class="btn continue-btn search-btn btn-block mb-0" @click="findDevice(searchText)" :disabled="!searchText || searchText === '' || searching">{{ searching ? "Searching..." : "Search" }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex';

    export default {
        data() {
            return {
                searchText: '',
                searching: false
            }
        },

        mounted() {
            this.clearOrderDetails();
        },

        methods: {
            ...mapActions(['clearOrderDetails', 'displayToast', 'loadDevice']),

            codeScanned(data) {
                if (!data.type || data.type !== 'asset') {
                    this.displayToast({text: "An invalid QR code has been scanned.", type: 'error'});
                    return;
                }

                this.findDevice(data.uuid);
            },

            findDevice(searchText) {
                if (this.searching) return;

                this.searching = true;

                this.loadDevice(searchText).then(() => {
                    this.searching = false;

                    this.$router.push({name: `dropOffDevice.confirmDevice`});

                }).catch(e => {
                    this.searching = false;
                    this.displayToast({text: e.response.data.messages[0], type: 'error'});
                })
            }
        }
    }
</script>